import React from 'react'

import { isObject, normalizeHtmlText } from '@wicadu/arepa/utils'

import HeadSEO from '@atoms/HeadSEO'
import { TemplateProvider } from '@hooks/useTemplate'
import Template from '@templates/Orders/CreateManualOrders'
import Consts from '@utils/constants'
import { IPageProps } from '@ts-types/Page'
import { usePageContext } from '@HOCs/Providers/PageContext'
import { PAGES_ID } from '@config/pages/const'
import { getUrlByPageId } from '@utils/getUrlByPageId'
import {
  ArticleContent,
  ArticleSection,
  ContentType,
} from '@ts-types/ArticleSection'
import { Translations } from '@ts-types/Translations'
import { getLinksFromAnchorTag } from '@utils/getLinksFromAnchorTag'

interface Props extends IPageProps {}

const { CREATE_MANUAL_USER_ORDERS_TRANSLATIONS } = Consts.translates

export default function OrdersCreateContainer() {
  const { lang } = usePageContext()

  const sections = Object.values(
    CREATE_MANUAL_USER_ORDERS_TRANSLATIONS[lang].ARTICLE
  ).filter((t): t is Translations => isObject(t))

  const intended =
    CREATE_MANUAL_USER_ORDERS_TRANSLATIONS[lang].ARTICLE['INTENDED'] || []

  return (
    <TemplateProvider
      value={{
        translate: CREATE_MANUAL_USER_ORDERS_TRANSLATIONS,
        lang,
        sections,
        intended,
      }}
    >
      <Template />
    </TemplateProvider>
  )
}

export function Head({ pageContext }: Props) {
  const { lang } = pageContext || {}

  const { organization, logo } = Consts.schemeOrg
  const {
    ABOUT_CREATE_ORDERS,
    BEFORE_BEGIN,
    HOW_TO_CREATE_ORDERS,
    NEXT_STEPS_ON_ORDERS,
    COMMON_ISSUES,
  } = CREATE_MANUAL_USER_ORDERS_TRANSLATIONS[lang].ARTICLE

  const articleSections = [
    {
      '@type': 'CreativeWork',
      headline: ABOUT_CREATE_ORDERS.TITLE,
      text: normalizeHtmlText(ABOUT_CREATE_ORDERS.CONTENT)?.join('. '),
    },
    {
      '@type': 'CreativeWork',
      headline: BEFORE_BEGIN.TITLE,
      text: normalizeHtmlText(
        BEFORE_BEGIN.CONTENT.filter(
          ({ CONTENT_TYPE }: ArticleSection) =>
            CONTENT_TYPE === ContentType.Paragraph
        ).map(({ CONTENT }: ArticleSection) => CONTENT.join(' '))
      )?.join('. '),
      hasPart: BEFORE_BEGIN.CONTENT.filter(
        ({ CONTENT_TYPE }: ArticleSection) =>
          CONTENT_TYPE === ContentType.UnorderedList
      ).flatMap(({ CONTENT }: ArticleContent) =>
        CONTENT.flatMap(({ CONTENT }: any) =>
          getLinksFromAnchorTag(CONTENT || [])
        )?.flatMap(({ url, name }: any) => ({
          '@type': 'CreativeWork',
          url,
          name,
        }))
      ),
    },
    {
      '@type': 'HowTo',
      headline: HOW_TO_CREATE_ORDERS.TITLE,
      step: HOW_TO_CREATE_ORDERS.CONTENT.map(
        ({ CONTENT }: ArticleContent, index: number) => ({
          '@type': 'HowToStep',
          position: index + 1,
          headline: normalizeHtmlText(
            (CONTENT as ArticleContent[])?.[0]?.CONTENT[0]
          ),
          text: normalizeHtmlText(
            (CONTENT as ArticleContent[])?.[1]?.CONTENT[0]
          ),
        })
      ),
    },
    {
      '@type': 'CreativeWork',
      headline: NEXT_STEPS_ON_ORDERS.TITLE,
      text: normalizeHtmlText(
        NEXT_STEPS_ON_ORDERS.CONTENT.filter(
          ({ CONTENT_TYPE }: ArticleSection) =>
            CONTENT_TYPE === ContentType.Paragraph
        ).map(({ CONTENT }: ArticleSection) => CONTENT.join(' '))
      )?.join('. '),
      hasPart: NEXT_STEPS_ON_ORDERS.CONTENT.filter(
        ({ CONTENT_TYPE }: ArticleSection) =>
          CONTENT_TYPE === ContentType.UnorderedList
      ).flatMap(({ CONTENT }: ArticleContent) =>
        (CONTENT as ArticleContent[]).flatMap(
          ({ CONTENT }: ArticleContent) => ({
            '@type': 'CreativeWork',
            headline: normalizeHtmlText(
              (CONTENT as ArticleContent[])?.[0]?.CONTENT[0]
            ),
            name: normalizeHtmlText(
              (CONTENT as ArticleContent[])?.[0]?.CONTENT[0]
            ),
            text: normalizeHtmlText(
              (CONTENT as ArticleContent[])?.[1]?.CONTENT[0]
            ),
            url: getLinksFromAnchorTag(
              ((CONTENT as ArticleContent[])?.[1]?.CONTENT as string[]) || []
            )?.[0]?.url,
          })
        )
      ),
    },
    {
      '@type': 'CreativeWork',
      headline: COMMON_ISSUES.TITLE,
      text: normalizeHtmlText(
        COMMON_ISSUES.CONTENT?.flatMap(
          ({ CONTENT }: ArticleContent) => CONTENT
        )?.join('\n\n')
      ),
    },
  ]

  return (
    <HeadSEO
      title={CREATE_MANUAL_USER_ORDERS_TRANSLATIONS[lang].SEO_TITLE as string}
      description={
        CREATE_MANUAL_USER_ORDERS_TRANSLATIONS[lang].SEO_DESCRIPTION as string
      }
      keywords={
        CREATE_MANUAL_USER_ORDERS_TRANSLATIONS[lang].SEO_KEYWORDS as string
      }
      lang={lang}
      pageId={PAGES_ID.ORDERS_HOME}
      jsonLd={[
        {
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          '@id': getUrlByPageId(PAGES_ID.CREATE_MANUAL_USER_ORDERS, lang),
          name: CREATE_MANUAL_USER_ORDERS_TRANSLATIONS[lang].SEO_TITLE,
          description:
            CREATE_MANUAL_USER_ORDERS_TRANSLATIONS[lang].SEO_DESCRIPTION,
          url: getUrlByPageId(PAGES_ID.CREATE_MANUAL_USER_ORDERS, lang),
          breadcrumb: {
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: CREATE_MANUAL_USER_ORDERS_TRANSLATIONS[lang].BREADCRUMBS[
                  'HOME'
                ],
                item: getUrlByPageId(PAGES_ID.HOME, lang),
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: CREATE_MANUAL_USER_ORDERS_TRANSLATIONS[lang].BREADCRUMBS[
                  'ORDERS_HOME'
                ],
                item: getUrlByPageId(PAGES_ID.ORDERS_HOME, lang),
              },
              {
                '@type': 'ListItem',
                position: 3,
                name: CREATE_MANUAL_USER_ORDERS_TRANSLATIONS[lang].BREADCRUMBS[
                  'CREATE'
                ],
                item: getUrlByPageId(PAGES_ID.CREATE_MANUAL_USER_ORDERS, lang),
              },
            ],
          },
          mainEntity: {
            '@type': 'Article',
            headline: CREATE_MANUAL_USER_ORDERS_TRANSLATIONS[lang].SEO_TITLE,
            description:
              CREATE_MANUAL_USER_ORDERS_TRANSLATIONS[lang].SEO_DESCRIPTION,
            image: logo.logoField,
            datePublished: '2025-03-30T00:00:00Z',
            dateModified: '2025-03-30T00:00:00Z',
            keywords: CREATE_MANUAL_USER_ORDERS_TRANSLATIONS[lang].SEO_KEYWORDS,
            author: organization.organizationField,
            publisher: organization.organizationField,
            hasPart: articleSections,
          },
        },
      ]}
    />
  )
}
